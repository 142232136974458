@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "League Spartan";
  src: url(fonts/league_spartan.ttf);
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  color: black;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

code {
  margin: 0;
}

:root {
  margin: 0;
  height: 100%;
}
